<template>
  <li
    class="vWaiterItem"
    @click="onWainerInfo"
  >
    <div class="vWaiterItem__wrap">
      <div
        v-if="data.avatar || data.avatar_link"
        class="vWaiterItem__ava"
      >
        <img
          :src="data.avatar || data.avatar_link"
          alt="avatar"
        >
      </div>
      <div class="vWaiterItem__section">
        Официант
        <span class="vWaiterItem__waiterName">
          {{ data.display_name ?? data.name }}
        </span>
      </div>
    </div>
    <div class="vWaiterItem__section">
      Статус
      <span
        class="vWaiterItem__waiterStatus"
        :class="{
          waiterIsVerified: data.waiter_status === 'VERIFIED',
          invitedSystem: data.waiter_status === 'INVITED',
        }"
      >
        {{ statusWaiter }}
      </span>
    </div>
    <svg
      class="vWaiterItem__arrow"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16L14 12L10 8"
        stroke="#80869A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </li>
</template>

<script>
export default {
  name: 'VWaiterItem',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  emits: ['onHandler', 'emailReverce', 'waiterStatus'],
  data() {
    return {
      verif: '',
    }
  },
  computed: {
    statusWaiter() {
      const status = this.data.waiter_status
      return status === 'NOT INVITED'
        ? 'Ожидает приглашения'
        : status === 'INVITED'
        ? 'Приглашён в систему'
        : 'Официант верифицирован'
    },
  },
  methods: {
    onWainerInfo() {
      console.log('onHandler', this.data)
      this.$emit('emailReverce', this.data.email)
      this.$emit('onHandler', this.data)
      this.$emit('waiterStatus', this.data.waiter_status)
    },
  },
}
</script>
<style lang="scss">
.vWaiterItem {
  width: 100%;
  max-width: calc(50% - 16px);
  height: 108px;
  border: 1px solid #e6e7eb;
  background: #fff;
  border-radius: 11px;
  margin-bottom: 24px;
  padding: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 1000px) {
    padding: 24px 22px 24px 12px;
  }
  @media (max-width: 900px) {
    max-width: 100%;
    margin-bottom: 12px;
  }
  @media (max-width: 470px) {
    padding: 24px 10px 24px 12px;
    align-items: flex-start;
  }
  &__wrap {
    display: flex;
    align-items: center;
    width: 50%;
    padding-right: 10px;
    @media (max-width: 1000px) {
      padding-right: 12px;
    }
  }
  &__ava {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #80869a;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 60px;
    @media (max-width: 769px) {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    color: #80869a;
    font-size: 16px;
    line-height: 20px;
    margin-right: 24px;
    @media (max-width: 1000px) {
      margin-right: 0;
    }
    @media (max-width: 769px) {
      font-size: 14px;
    }
    @media (max-width: 425px) {
      max-width: 120px;
    }
    &:last-child {
      margin-right: 0;
    }
    //span {
    //  color: #343D5D;
    //  font-size: 15px;
    //  @media(max-width: 769px) {
    //    font-size: 12px;
    //    line-height: 16px;
    //  }
    //}
  }
  &__arrow {
    margin-left: auto;
    min-width: 24px;
  }
  &__waiterName {
    color: #343d5d;
    font-size: 15px;
    @media (max-width: 769px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  &__waiterStatus {
    color: #676e86;
    font-size: 15px;
    font-weight: 500;
    @media (max-width: 769px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .waiterIsVerified {
    color: #010d35;
    //color: red;
  }
  .invitedSystem {
    color: #343d5d;
    //color: green;
  }
}
</style>
