<template>
  <Form
    v-slot="{ errors }"
    class="section-profile__form"
    @submit="checkForm"
  >
    <div class="waiter-status">
      Статус: <span>{{ status }}</span>
    </div>
    <!-- Аватарка -->
    <div
      v-if="waiterData.avatar === 'waiter'"
      class="section-profile__photo-block"
    >
      <div class="section-profile__photo-wrapper">
        <div class="section-profile__photo-del">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L1 9M1 1L9 9L1 1Z"
              stroke="#EC4E4E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <img
          :src="waiterData.avatar"
          alt="avatar"
          class="section-profile__photo"
        >
      </div>
    </div>

    <fieldset class="section-profile__group">
      <!-- Имя -->
      <vInput
        id="name"
        v-model="name"
        :value="name"
        placeholder="Имя на кассе"
        rules="required"
        :disabled="true"
      />

      <vInput
        id="display_name"
        v-model="display_name"
        :value="display_name"
        placeholder="Имя"
        :disabled="true"
      />
    </fieldset>

    <fieldset class="section-profile__group">
      <span
        v-if="!isTechSupport"
        class="section-profile__subtitle"
      >Пригласить официанта в систему
      </span>
      <!-- E-mail -->
      <vInput
        id="email"
        v-model="email"
        :value="email"
        placeholder="Почта"
        rule="mail|max:254"
        :error="identicalMail"
        :disabled="
          statusWaiterLocal === 'INVITED' ||
            statusWaiterLocal === 'VERIFIED' ||
            isTechSupport
        "
        :empty-field-error="true"
      />
    </fieldset>

    <vInput
      v-if="isTechSupport"
      id="loginTelegram"
      v-model="loginTelegram"
      :value="loginTelegram"
      placeholder="Имя Telegram"
      rule="max:254"
      :disabled="false"
      :empty-field-error="true"
    />

    <vInput
      v-if="isTechSupport"
      id="idTelegram"
      v-model="idTelegram"
      :value="idTelegram"
      type="number"
      placeholder="Id Telegram"
      :disabled="false"
      :empty-field-error="true"
    />

    <vButtonRed
      v-if="statusWaiterLocal === 'INVITED' && !isTechSupport"
      @click="toggleConfirm"
    >
      Отменить приглашение
    </vButtonRed>

    <vButtonRed
      v-if="statusWaiterLocal === 'VERIFIED' && !isTechSupport"
      @click="toggleConfirmUntying"
    >
      Отвязать почту
    </vButtonRed>

    <vButton
      v-if="
        statusWaiterLocal !== 'INVITED' &&
          statusWaiterLocal !== 'VERIFIED' &&
          !isTechSupport
      "
      type="submit"
      :disabled="!email.length || Object.keys(errors).length"
    >
      Пригласить официанта
    </vButton>

    <vButton
      v-if="isTechSupport"
      type="submit"
      :disabled="disabledSaveTelegramButton || Object.keys(errors).length"
      @click.prevent="saveTelegramData"
    >
      Сохранить
    </vButton>

    <vModalCancelInvitation
      v-if="confirm"
      @toggleConfirm="toggleConfirm"
      @cancellationInvitation="cancellationInvitation"
    />

    <vModalUnlinkEmail
      v-if="confirmUntying"
      @toggleConfirmUntying="toggleConfirmUntying"
      @untyingEmail="untyingEmail"
    />
  </Form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Form } from 'vee-validate'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import vModalCancelInvitation from '@/components/modals/v-modal-cancel-invitation'
import vModalUnlinkEmail from '@/components/modals/v-modal-unlink-email'
import VButtonRed from '@/components/v-button-red'
export default {
  name: 'VWaiterForm',
  components: {
    VButtonRed,
    Form,
    vInput,
    vButton,
    vModalCancelInvitation,
    vModalUnlinkEmail,
  },
  props: {
    waiterData: {
      type: Object,
      required: true,
    },
    emailReverce: {
      type: String,
      required: true,
    },
    waiterStatus: {
      type: String,
      required: true,
    },
    isTechSupport: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleWaierFormElement', 'resetSearchField', 'updateItem'],
  data() {
    return {
      name: '',
      email: '',
      loginTelegram: '',
      idTelegram: '',
      id: '',
      display_name: '',
      confirm: false,
      confirmUntying: false,
      identicalMail: '',
      statusWaiterLocal: '',
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('waiter', ['WAITERS_ERROR']),
    status() {
      let res = ''
      switch (this.statusWaiterLocal) {
        case 'INVITED':
          res = 'Приглашен в систему'
          break
        case 'VERIFIED':
          res = 'Официант верифицирован'
          break
        default:
          res = 'Ожидает приглашения'
      }
      return res
    },
    disabledSaveTelegramButton() {
      return (
        !this.idTelegram.length ||
        this.idTelegram.length < 5 ||
        !this.loginTelegram.length
      )
    },
  },
  watch: {
    email() {
      this.identicalMail = ''
    },
  },
  mounted() {
    this.name = this.waiterData.name
    this.display_name = this.waiterData.display_name ?? ''
    this.id = this.waiterData.external_id
    this.email = this.emailReverce ? this.emailReverce : ''
    this.statusWaiterLocal = this.waiterStatus

    this.loginTelegram = this.waiterData.telegram_name || ''
    this.idTelegram = String(this.waiterData.telegram_id) || ''
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('waiter', [
      'INVITATION',
      'UNDOINVITATION',
      'UNLINK_EMAIL',
      'SAVE_TELEGRAM_DATA',
    ]),
    saveDisplayName() {
      console.log('saveDisplayName', this.display_name)
    },
    async checkForm() {
      this.identicalMail = ''
      await this.TOGGLE_PRELOADER(true)
      await this.INVITATION({
        id: this.id,
        email: this.email,
        token: this.GET_AUTHENTIFICATED,
      })
      if (this.WAITERS_ERROR) {
        this.identicalMail = 'E-mail уже привязан к другому официанту'
      } else {
        this.statusWaiterLocal = 'INVITED'
      }
      this.TOGGLE_PRELOADER(false)
    },

    toggleConfirm() {
      this.confirm = !this.confirm
    },

    toggleConfirmUntying() {
      this.confirmUntying = !this.confirmUntying
    },

    async cancellationInvitation() {
      await this.UNDOINVITATION({
        external_id: this.id,
        token: this.GET_AUTHENTIFICATED,
      })
      // this.$emit("resetSearchField");
      // this.$emit("toggleWaierFormElement");
      this.statusWaiterLocal = 'NOT INVITED'
      this.email = ''
      this.confirm = !this.confirm
    },

    async untyingEmail() {
      await this.UNLINK_EMAIL({
        external_id: this.id,
        token: this.GET_AUTHENTIFICATED,
      })
      this.email = ''
      this.statusWaiterLocal = 'NOT INVITED'
      //this.$emit("resetSearchField");
      //this.$emit("toggleWaierFormElement");
      this.confirmUntying = !this.confirmUntying
    },

    async saveTelegramData() {
      await this.TOGGLE_PRELOADER(true)

      const data = {
        user_id: this.waiterData.user_id,
        telegram_nickname: this.loginTelegram,
        telegram_id: +this.idTelegram,
      }

      await this.SAVE_TELEGRAM_DATA({
        token: this.GET_AUTHENTIFICATED,
        ...data,
      })

      this.$emit('toggleWaierFormElement')

      setTimeout(async () => {
        await this.TOGGLE_PRELOADER(false)
      }, 500)
    },
  },
}
</script>
<style lang="scss" scoped>
.waiter-status {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-end;
  color: #bbb;
  font-size: 14px;
  span {
    color: #353841;
    padding-left: 5px;
    font-size: 16px;
  }
}
</style>
