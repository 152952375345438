<template>
  <div class="employeeSearch__wrap">
    <div class="employeeSearch">
      <div class="employeeSearch__field-wrap">
        <input
          id="search"
          v-model="searchData"
          class="employeeSearch__field"
          :class="{ active: searchData.length }"
          type="text"
          @input="onInput($event.target.value)"
        >
        <div class="employeeSearch__label">
          Введите имя сотрудника..
        </div>
      </div>

      <svg
        class="employeeSearch__magnifier"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 15L11.71 11.71M11.7138 2.8382C14.1647 5.28913 14.1647 9.26287 11.7138 11.7138C9.26287 14.1647 5.28913 14.1647 2.8382 11.7138C0.387267 9.26287 0.387267 5.28913 2.8382 2.8382C5.28913 0.387267 9.26287 0.387267 11.7138 2.8382V2.8382Z"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <transition name="fade">
      <button
        v-show="searchData.length"
        class="employeeSearch__btn-reset"
        @click="onInput('')"
      >
        Сбросить
      </button>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'VEmployeeSearch',
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: ['update:modelValue', 'search', 'func'],
  data() {
    return {
      searchData: '',
    }
  },
  watch: {
    value() {
      if (this.value === '') this.searchData = ''
      console.log('value', this.value)
      console.log('searchData', this.searchData)
    },
  },
  methods: {
    onInput(val) {
      this.$emit('update:modelValue', val)
      this.$emit('func', true)
      this.searchData = val
      this.throttleMethod()
    },
    throttleMethod: _.debounce(function () {
      this.$emit('search', this.searchData)
    }, 500),
  },
}
</script>

<style lang="scss">
.employeeSearch {
  position: relative;
  width: 100%;
  max-width: 389px;
  height: 30px;
  margin-left: 65px;
  @media (max-width: 900px) {
    margin-left: 0;
    width: 100%;
    max-width: none;
  }

  &__wrap {
    display: flex;
    width: 100%;
  }

  &__field {
    width: 100%;
    height: 100%;
    background: transparent;
    line-height: 24px;
    font-weight: 500;
    font-size: 13px;
    position: relative;
    z-index: 2;
    color: #010d35;
    outline: none;
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    padding: 0 0 0 36px;
    color: #010d35;
    &:focus {
      border: 1px solid #6764ff;
      caret-color: #6764ff;
      font-size: 13px;
      margin-right: 40px;
    }
    &.active + .employeeSearch__label,
    &:focus + .employeeSearch__label {
      display: none;
    }
  }
  &__field-wrap {
    background: #fff;
    height: 30px;
  }
  &__label {
    position: absolute;
    top: 50%;
    left: 36px;
    line-height: 18px;
    color: #80869a;
    transform: translateY(-50%);
    transition: all 0.3s;
    z-index: 1;
    font-weight: 400;
    font-size: 13px;
  }
  &__magnifier {
    position: absolute;
    stroke: #80869a;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
  }

  &__btn-reset {
    width: 86px;
    height: 30px;
    margin: 0 auto 0 6px;
    color: #ec4e4e;
    line-height: 1;
    font-weight: 600;
    font-family: 'Commissioner', 'sans-serif';
    text-align: center;
    background: rgba(254, 195, 195, 0.15);
    border: 1px solid #ec4e4e;
    border-radius: 6px;
    cursor: pointer;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
