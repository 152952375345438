<template>
  <button
    class="vButtonRed"
    :type="type"
    :disabled="disabled"
    :class="{ fixWidth: fixWidth, 'vButtonRed--gray': theme === 'gray' }"
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VButtonRed',
  props: {
    type: {
      type: String,
      require: false,
      default: 'button',
    },
    theme: {
      type: String,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    fixWidth: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  emits: ['handleClick'],
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
}
</script>

<style lang="scss">
.vButtonRed {
  margin-bottom: 16px;
  padding: 16px 0;
  width: 100%;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #ec4e4e;
  background: #fff6f6;
  border: 1px solid #ec4e4e;
  border-radius: 12px;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  svg {
    margin-left: 12px;
  }
  &.fixWidth {
    max-width: 313px;
  }
  &:disabled {
    color: #fec3c3;
    background: #fff6f6;
    border-color: #fff6f6;
    cursor: not-allowed;
  }
  &--gray {
    color: #343d5d;
    background: #edeef1;
    // &:hover {
    //   background: linear-gradient(223.61deg, #547AFF 0%, #413DFF 100%);
    //   color: #FFFFFF;
    // }
  }
}
</style>
