<template>
  <div class="vPagination">
    <div
      v-if="page !== 1"
      class="vPagination__arrow"
      @click="onHandler(prevPage)"
    >
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 9L1 5L5 1"
          stroke="#80869A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <ul class="vPagination__list">
      <li
        v-if="toCounter[0] > 2"
        class="vPagination__item"
        @click="onHandler(1)"
      >
        {{ 1 }}
      </li>
      <li
        v-if="toCounter[0] > 3"
        class="vPagination__item dots"
      >
        ...
      </li>
      <li
        v-if="prevPage !== 0 && toCounter.length > 2"
        class="vPagination__item"
        @click="onHandler(prevPage)"
      >
        {{ prevPage === toCounter[0] ? prevPage - 1 : prevPage }}
      </li>
      <li
        v-for="item in toCounter"
        :key="item"
        :class="['vPagination__item', { active: page === item }]"
        @click="onHandler(item)"
      >
        {{ item }}
      </li>
      <li
        v-if="prevPage == 0 && toCounter.length > 2"
        class="vPagination__item"
        @click="onHandler(nextPage)"
      >
        {{ nextPage }}
      </li>
      <li
        v-if="toCounter[0] < pages - 2"
        class="vPagination__item dots"
      >
        ...
      </li>
      <li
        v-if="toCounter[0] < pages - 1"
        class="vPagination__item"
        @click="onHandler(pages)"
      >
        {{ pages }}
      </li>
    </ul>
    <div
      v-if="page < pages"
      class="vPagination__arrow"
      @click="onHandler(nextPage - 1)"
    >
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 9L5 5L1 1"
          stroke="#80869A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VPagination',
  props: {
    pages: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  emits: ['onHandler'],
  computed: {
    prevPage() {
      return this.page - 1
    },
    nextPage() {
      return this.page + 2
    },
    toCounter() {
      let arr = []
      for (let i = 0; i < this.pages; i++) {
        arr.push(i + 1)
      }
      let arrFiltered =
        this.pages !== this.page ? arr.splice(this.page - 1, 2) : arr.slice(-2)
      return arrFiltered
    },
  },
  methods: {
    onHandler(num) {
      this.$emit('onHandler', num)
    },
  },
}
</script>
<style lang="scss">
.vPagination {
  display: flex;
  align-items: center;
  &__arrow {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__list {
    display: flex;
    align-items: center;
    margin: 0px 4px;
  }
  &__item {
    color: #343d5d;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    margin: 0 5px;
    &:not(.dots):hover {
      border-color: #343d5d;
      cursor: pointer;
    }
    &.active {
      background: #e6e7eb;
      border-color: transparent;
      cursor: default;
      &:hover {
        border-color: transparent;
        cursor: default;
      }
    }
  }
}
</style>
