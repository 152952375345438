<template>
  <vue100vh :css="{ height: 'calc(100rvh - 90px)' }">
    <section class="vSectionWaitersection section-profile">
      <div class="vSectionWaitersection__listSearch">
        <h2
          v-if="!waiterForm"
          class="section-profile__title"
        >
          Список официантов
        </h2>
        <div
          v-show="!waiterForm"
          class="vSectionWaitersection__top"
        >
          <VEmployeeSearch
            v-model="search"
            :value="search"
            @search="searchWaiters"
            @func="togglePreloader"
          />
          <button
            class="vSectionWaitersection__btn"
            :disabled="disabledBtn"
            @click="updateList"
          >
            Обновить список
          </button>
        </div>
      </div>
      <div
        v-if="waiterForm"
        class="vSectionWaitersection__back"
        @click="waiterForm = false"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 8L10 12L14 16"
            stroke="#6764FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Назад
      </div>
      <div
        :class="[
          'vSectionWaitersection__container',
          {
            'vSectionWaitersection--flex':
              WAITERS.waiters && WAITERS.waiters.length == 0,
          },
        ]"
      >
        <ul
          v-if="!waiterForm && !preloader"
          class="vSectionWaitersection__list"
          :class="test < 10 ? 'columnCount1' : 'columnCount2'"
        >
          <vWaiterItem
            v-for="waiter in WAITERS.waiters"
            :key="waiter.id"
            :data="waiter"
            @onHandler="toggleWaierForm"
            @emailReverce="onEmailReverce"
            @waiterStatus="onWaiterStatus"
          />
        </ul>
        <div
          v-if="WAITERS.waiters && WAITERS.waiters.length == 0"
          class="vSectionWaitersection__empty-search"
        >
          {{
            WAITERS.waiters && WAITERS.waiters.length == 0
              ? 'Нет результатов. Попробуйте ввести данные ещё раз'
              : ''
          }}
        </div>
        <vLightPreloader
          v-show="preloader"
          :fixed="false"
          :text="textPreloader"
          background="#f8f9fd"
        />
        <vWaiterForm
          v-if="waiterForm"
          :waiter-data="waiterData"
          :email-reverce="emailReverce"
          :waiter-status="waiterStatus"
          :is-tech-support="isTechSupport"
          @resetSearchField="resetSearchField"
          @toggleWaierFormElement="toggleWaierFormElement"
        />
        <vPagination
          v-if="!waiterForm && WAITERS.pages > 1"
          :pages="WAITERS.pages"
          :page="WAITERS.pageCurrent"
          @onHandler="onClickPaination"
        />
      </div>
    </section>
  </vue100vh>
</template>

<script>
import vLightPreloader from '@/components/v-light-preloader'
import { mapGetters, mapActions } from 'vuex'
import vWaiterItem from '@/components/v-waiter-item.vue'
import vPagination from '@/components/v-pagination.vue'
import vWaiterForm from '@/components/v-waiter-form.vue'
import VEmployeeSearch from '@/components/v-employee-search.vue'
import vue100vh from 'vue-100vh'
export default {
  name: 'VSectionWaiter',
  components: {
    vWaiterItem,
    vPagination,
    vWaiterForm,
    VEmployeeSearch,
    vLightPreloader,
    vue100vh,
  },
  props: {
    restId: {
      type: Number,
      default: null,
    },
    isTechSupport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: null,
      waiterForm: false,
      waiterData: null,
      test: 10,
      search: '',
      preloader: false,
      disabledBtn: false,
      textPreloader: 'Ищем совпадения',
      pageNumber: 1,
      emailReverce: '',
      waiterStatus: '',
    }
  },
  computed: {
    ...mapGetters('waiter', ['WAITERS']),
    ...mapGetters('user', ['USER_DATA', 'GET_AUTHENTIFICATED']),
  },

  async mounted() {
    await this.SEARCH_WAITERS({
      page: 1,
      limit: 10,
      search: '',
      token: this.GET_AUTHENTIFICATED,
      restId: this.restId,
    })
    setTimeout(() => {
      this.test = Object.keys(this.WAITERS.waiters).length
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },

  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('waiter', ['SEARCH_WAITERS', 'UPDATE_WAITERS']),

    async updateList() {
      this.textPreloader = 'Обновляем список официантов'
      this.disabledBtn = true
      this.togglePreloader(true)
      await this.UPDATE_WAITERS(this.GET_AUTHENTIFICATED)

      await this.SEARCH_WAITERS({
        page: this.pageNumber,
        limit: 10,
        search: '',
        token: this.GET_AUTHENTIFICATED,
        restId: this.restId,
      })

      this.togglePreloader(false)
      this.disabledBtn = false
      this.textPreloader = 'Ищем совпадения'
    },

    async searchWaiters(searchData) {
      await this.SEARCH_WAITERS({
        page: '1',
        limit: 10,
        search: searchData,
        token: this.GET_AUTHENTIFICATED,
        restId: this.restId,
      })
      this.test = Object.keys(this.WAITERS.waiters).length
      this.togglePreloader(false)
    },
    async resetSearch() {
      this.togglePreloader(true)
      this.search = ''
      await this.SEARCH_WAITERS({
        page: '1',
        limit: 10,
        search: '',
        token: this.GET_AUTHENTIFICATED,
        restId: this.restId,
      })
      this.togglePreloader(false)
    },

    togglePreloader(val) {
      this.preloader = val
    },
    async onClickPaination(num) {
      this.TOGGLE_PRELOADER(true)
      this.pageNumber = num
      await this.SEARCH_WAITERS({
        page: num,
        limit: 10,
        search: this.search,
        token: this.GET_AUTHENTIFICATED,
        restId: this.restId,
      })

      this.TOGGLE_PRELOADER(false)
      this.test = Object.keys(this.WAITERS.waiters).length
    },
    toggleWaierForm(waiter) {
      this.waiterData = waiter
      this.waiterForm = !this.waiterForm
    },
    resetSearchField() {
      this.search = ''
    },
    toggleWaierFormElement() {
      this.waiterForm = !this.waiterForm
      // this.TOGGLE_PRELOADER(true)
      // setTimeout(async () => {
      //   await this.SEARCH_WAITERS({
      //     page: '1',
      //     limit: 10,
      //     search: '',
      //     token: this.GET_AUTHENTIFICATED,
      //     restId: this.restId,
      //   })
      //   this.TOGGLE_PRELOADER(false)
      // }, 500)
    },

    onEmailReverce(e) {
      this.emailReverce = e
    },

    onWaiterStatus(e) {
      this.waiterStatus = e
    },
  },
}
</script>

<style lang="scss" scoped>
.vSectionWaitersection {
  @media (max-width: 900px) {
    padding-bottom: 0 !important;
    min-height: 100%;
  }
  @media (max-width: 561px) {
    height: 100%;
    min-height: auto;
  }
  &__listSearch {
    display: flex;
    @media (max-width: 900px) {
      flex-direction: column-reverse;
      margin-bottom: 22px;
      .section-profile__title {
        display: none;
      }
    }
  }
  &__container {
    position: relative;
    min-height: 500px;
    @media (max-width: 900px) {
      min-height: auto;
      height: calc(100% - 94px);
      padding-bottom: 95px;
    }
  }
  &__back {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 142px);
    @media (max-width: 900px) {
      flex-direction: column-reverse;
      width: 100%;
    }
  }
  &__btn {
    display: inline-flex;
    flex-shrink: 0;
    margin-left: 10px;
    width: 135px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 2px;
    border: none;
    cursor: pointer;
    background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    &:disabled {
      background: #e6e7eb;
      color: #010d35;
      cursor: no-drop;
    }
    @media (max-width: 900px) {
      width: 100%;
      margin-bottom: 12px;
      margin-left: 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
    &::-webkit-scrollbar {
      @media (max-width: 425px) {
        width: 0;
      }
    }
    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-start;
      overflow: auto;
      flex-wrap: nowrap;
      height: calc(100% - 32px);
      min-height: 200px;
    }
  }
  .columnCount1 {
    column-count: 1;
    @media (max-width: 425px) {
      column-count: auto;
    }
  }
  .columnCount2 {
    column-count: 2;
    @media (max-width: 900px) {
      column-count: 1;
    }
    @media (max-width: 425px) {
      column-count: auto;
    }
  }

  &__empty-search {
    width: fit-content;
    max-width: 270px;
    margin: auto auto;
    text-align: center;
    color: #80869a;
  }

  &--flex {
    display: flex;
  }
}
.section-profile {
  @media (max-width: 425px) {
    padding: 0 16px 100px 16px;
  }
}
</style>
